import { BrowserProvider, Contract, parseUnits } from 'ethers'; // Import necessary utilities directly
import {
    CHAIN_CONFIG,
    FACTORY_CONTRACT_ADDRESS,
    WOODS_TOKEN,
} from '../../config/config.wods';
import toast from 'react-hot-toast';
import { customToastStyle } from '../lib';
import { ADMIN_FACTORY_ABI } from '../abi/Admin';

const UseWriteContract = () => {
    const writeToContract = async (
        desc,
        outcomes,
        tokenDecimal,
        endTimestamp,
        minPrice,
        maxPrice
    ) => {
        try {
            if (!window.ethereum) {
                return toast.error('Metamask not found', customToastStyle);
            }

            const provider = new BrowserProvider(window.ethereum);
            const chainData = { chainId: CHAIN_CONFIG.chainId };

            await Promise.all([
                provider.send('wallet_addEthereumChain', [CHAIN_CONFIG]),
                provider.send('wallet_switchEthereumChain', [chainData]),
            ]);

            const signer = await provider.getSigner();
            const contract = new Contract(
                FACTORY_CONTRACT_ADDRESS,
                ADMIN_FACTORY_ABI,
                signer
            );

            // Convert minPrice and maxPrice to smallest unit using parseUnits
            const minPriceWei = parseUnits(minPrice.toString(), 18);
            const maxPriceWei = parseUnits(maxPrice.toString(), 18);

            const txResponse = await contract.deployMarket(
                WOODS_TOKEN,
                desc,
                outcomes,
                tokenDecimal,
                endTimestamp,
                minPriceWei,
                maxPriceWei
            );

            const receipt = await txResponse.wait();
            const [platformAddress, platformFee] =
                await contract.getPlatformDetails();

            return {
                hash: receipt.hash,
                address: receipt.logs[0]?.address,
                platformAddress,
                platformFee: Number(platformFee),
            };
        } catch (error) {
            console.error('Error writing to contract:', error);
            toast.error(
                'Something went wrong, please try again',
                customToastStyle
            );
        }
    };

    return { writeToContract };
};

export default UseWriteContract;
