import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ImagePlus, Link, DollarSign, Clock } from 'lucide-react';
import UseWriteContract from '../../../extra/hooks/UseWriteContract';
import { writeContractApi } from '../../../extra/api';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';

const CampaignForm = () => {
    const [imagePreview, setImagePreview] = useState('');
    const [formData, setFormData] = useState({
        image: null,
        name: '',
        description: '',
        outcomes: ['Yes', 'No'],
        referenceLink: '',
        minBet: '',
        maxBet: '',
        endTime: '',
    });
    const [contractData, setContractData] = useState({
        token_address: '',
        token_symbol: '',
        token_decimal: '',
        end_time: new Date(),
        min_price: '',
        max_price: '',
        outcomes: formData.outcomes.join(','),
    });
    const { writeToContract } = UseWriteContract();
    const [isLoading, setIsLoading] = useState(false);
    const [trxHash, setTrxHash] = useState('');
    const [error, setError] = useState('');

    const customToastStyle = { autoClose: 3000, position: 'top-center' };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                setError('Image size should be less than 5MB');
                return;
            }
            setError('');
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            setFormData({ ...formData, image: file });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDeployContract = async (e) => {
        e.preventDefault();
        const outcomesString = contractData.outcomes;
        const formattedOutcomes = outcomesString
            .split(',')
            .map((outcome) => outcome.trim());

        try {
            setIsLoading(true);
            const res = await writeToContract(
                formData.name,
                formattedOutcomes,
                contractData.token_decimal,
                Math.floor(new Date(formData.endTime).getTime() / 1000),
                formData.minBet,
                formData.maxBet
            );

            if (res) {
                setTrxHash(res.hash);

                await writeContractApi({
                    market_info: {
                        name: formData.name,
                        min_price: formData.minBet,
                        max_price: formData.maxBet,
                        description: formData.description,
                        reference: formData.referenceLink,
                        platform_fee: res.platformFee,
                        platform_address: res.platformAddress,
                        token_address: contractData.token_address,
                        token_symbol: contractData.token_symbol,
                        token_decimal: contractData.token_decimal,
                    },
                    address: res.address,
                    status: 0,
                    outcomes: formattedOutcomes,
                    image: formData.image,
                    image_type: formData.image?.type,
                    end_time: formData.endTime,
                }).unwrap();

                toast.success(
                    'Prediction deployed successfully',
                    customToastStyle
                );
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            toast.error('Something went wrong', customToastStyle);
        }
    };
    // Get current datetime string for min attribute
    const getCurrentDateTime = () => {
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        return now.toISOString().slice(0, 16);
    };

    return (
        <div className="min-h-screen bg-black ">
            <Header />
            <div className="py-12 box">
                {' '}
                {/* Added spacing after header */}
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
                    <div className="bg-black rounded-lg shadow-xl overflow-hidden">
                        <div className="p-6">
                            <h2 className="text-2xl font-bold text-center mb-8 text-white">
                                Create new prediction campaign
                            </h2>

                            <form className="space-y-8">
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                    {/* Left Column */}
                                    <div className="space-y-6">
                                        {/* Image Upload */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                                Campaign Image
                                            </label>
                                            <div className="border-2 border-dashed border-gray-600 rounded-lg p-4 text-center">
                                                <input
                                                    type="file"
                                                    id="image"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    className="hidden"
                                                />
                                                <label
                                                    htmlFor="image"
                                                    className="cursor-pointer block"
                                                >
                                                    {imagePreview ? (
                                                        <img
                                                            src={imagePreview}
                                                            alt="Preview"
                                                            className="max-w-sm mx-auto rounded-lg"
                                                        />
                                                    ) : (
                                                        <div className="space-y-2">
                                                            <ImagePlus className="h-12 w-12 mx-auto text-gray-400" />
                                                            <span className="text-sm text-gray-400 block">
                                                                Click to upload
                                                                image (max 5MB)
                                                            </span>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>

                                        {/* Campaign Name */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                                Campaign Name
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Enter campaign name"
                                                value={formData.name}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        name: e.target.value,
                                                    })
                                                }
                                                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                            />
                                        </div>

                                        {/* Campaign Description */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                                Campaign Description
                                            </label>
                                            <textarea
                                                placeholder="Enter campaign description"
                                                value={formData.description}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        description:
                                                            e.target.value,
                                                    })
                                                }
                                                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px] text-gray-200"
                                            />
                                        </div>
                                    </div>

                                    {/* Right Column */}
                                    <div className="space-y-6">
                                        {/* Prediction End Time */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                                Prediction End Time
                                            </label>
                                            <div className="relative">
                                                <Clock className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                                <input
                                                    type="datetime-local"
                                                    value={formData.endTime}
                                                    min={getCurrentDateTime()}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            endTime:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                                />
                                            </div>
                                        </div>

                                        {/* Outcomes */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                                Outcomes
                                            </label>
                                            <div className="grid grid-cols-2 gap-4">
                                                {formData.outcomes.map(
                                                    (outcome, index) => (
                                                        <input
                                                            key={index}
                                                            value={outcome}
                                                            onChange={(e) => {
                                                                const newOutcomes =
                                                                    [
                                                                        ...formData.outcomes,
                                                                    ];
                                                                newOutcomes[
                                                                    index
                                                                ] =
                                                                    e.target.value;
                                                                setFormData({
                                                                    ...formData,
                                                                    outcomes:
                                                                        newOutcomes,
                                                                });
                                                            }}
                                                            placeholder={`Outcome ${
                                                                index + 1
                                                            }`}
                                                            className="px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>

                                        {/* Reference Link */}
                                        <div>
                                            <label className="block text-sm font-medium text-gray-200 mb-2">
                                                Reference Link
                                            </label>
                                            <div className="relative">
                                                <Link className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                                <input
                                                    type="url"
                                                    placeholder="Enter reference link"
                                                    value={
                                                        formData.referenceLink
                                                    }
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            referenceLink:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                                />
                                            </div>
                                        </div>

                                        {/* Bet Amounts */}
                                        <div className="grid grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-200 mb-2">
                                                    Minimum Bet
                                                </label>
                                                <div className="relative">
                                                    <DollarSign className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                                    <input
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="0.00"
                                                        value={formData.minBet}
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                minBet: e.target
                                                                    .value,
                                                            })
                                                        }
                                                        className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-200 mb-2">
                                                    Maximum Bet
                                                </label>
                                                <div className="relative">
                                                    <DollarSign className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                                    <input
                                                        type="number"
                                                        step="0.01"
                                                        placeholder="0.00"
                                                        value={formData.maxBet}
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                maxBet: e.target
                                                                    .value,
                                                            })
                                                        }
                                                        className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-200"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Error Display */}
                                {error && (
                                    <div className="bg-red-900 border-l-4 border-red-500 p-4">
                                        <p className="text-red-200">{error}</p>
                                    </div>
                                )}

                                {/* Submit Button */}
                                <div className="grid grid-cols-2 gap-4 mr-4">
                                    <button
                                        type="button"
                                        onClick={handleDeployContract}
                                        className="w-full bg-yellow-700 text-white py-3 px-4 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600 transition-colors duration-200"
                                        disabled={isLoading}
                                    >
                                        {isLoading
                                            ? 'Deploying...'
                                            : 'Create prediction campaign'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CampaignForm;
