import React from 'react';
import { FaDollarSign } from 'react-icons/fa6';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import CONFIG from '../../config/config.wods';

export default function Header() {
    // Define the navigation links dynamically
    const navLinks = [
        { name: 'Predictions', path: '/predict' },
        { name: 'Create Predictions', path: '/create-prediction' },
        { name: 'Docs', path: '/docs' },
    ];

    return (
        <header className="box header-box mb-4">
            <div className="flex items-center justify-between w-full">
                <div>
                    <div
                        className="glitch-text text-yellow-500 cursor-pointer text-lg"
                        onClick={() => (window.location.href = '/')}
                    >
                        WODS
                    </div>
                    <small>Predict the future</small>
                </div>

                {/* Dynamically render navigation links */}
                <nav className="flex gap-8 text-white">
                    {navLinks.map((link) => (
                        <Link
                            key={link.name}
                            to={link.path}
                            className="hover:text-yellow-500 transition duration-200"
                        >
                            {link.name}
                        </Link>
                    ))}
                </nav>

                {/* Buy WODS Button */}
                <button
                    className="app-button-community ticker flex"
                    onClick={() => window.open(CONFIG.BUY_WODS_LINK, '')}
                >
                    <div className="ticker flex items-center">
                        Buy&nbsp;
                        <FaDollarSign /> WODS
                    </div>
                </button>
            </div>
        </header>
    );
}
