import { CURR_RPC_URL } from '../../config/config.wods';

export const writeContractApi = async (data) => {
    const token = localStorage.getItem('token'); // Get token from localStorage
    const source = localStorage.getItem('source'); // Get source from localStorage

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    // Add Authorization and source headers if they exist
    if (token && source) {
        headers.append('Authorization', `Bearer ${token}`);
        headers.append('source', source);
    }

    try {
        const response = await fetch(`${CURR_RPC_URL}/admin/predicto`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data), // Convert the data to JSON
        });

        if (!response.ok) {
            throw new Error('Failed to send data to the server');
        }

        const result = await response.json();
        return result; // Return the server response if the request was successful
    } catch (error) {
        console.error('Error during contract write:', error);
        throw error; // Throw error to be caught by calling function
    }
};
