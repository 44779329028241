import React from 'react';

export default function Footer() {
    return (
        <footer className="box footer-box my-4">
            <div className="social-links py-4">
                copyright - Wolf of Dumb Street
            </div>
            <div>54 • 19 998.5 • $WODS TO THE MOON</div>
        </footer>
    );
}
