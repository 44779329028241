module.exports = {
    SOCIAL_LINKS: {
        X: 'https://x.com/wodsfun',
        TG: 'https://telegram.me/+LpPt_S7ByI03OGU1',
        DOC: 'https://wodsfun.hashnode.space',
    },
    BUY_WODS_LINK:
        'https://app.uniswap.org/explore/tokens/base/0x4398c398e5ac747e6d51bf1db1dac346ca90fee0',
    CURR_RPC_URL: '',
    CHAIN_CONFIG: '',
    FACTORY_CONTRACT_ADDRESS: '',
    WOODS_TOKEN: '',
    CA_ADDRESS: '0x4398c398e5ac747e6d51bf1db1dac346ca90fee0',
    PREDICTION_MARKET: [
        {
            title: 'Will SUI overtake Solana as the most used blockchain for new gaming projects by Q4 2025?',
            options: ['YES', 'NO'],
            image: 'http://cdn.ebaumsworld.com/mediaFiles/picture/1035099/85708057.jpg',
            info: {
                description:
                    'This campaign evaluates the competitive trajectory of SUI and Solana in capturing new gaming project adoption by Q4 2025.',
                fees: '1',
            },
            contract_address: '0xABC123456789DEF01',
            start_date: '2024-11-01',
            end_date: '2025-12-31',
        },
        {
            title: 'Which AAA studio will be the first to release a fully on-chain game by Q3 2025?',
            options: ['GUNZILLA', 'UBISOFT', 'EA GAMES'],
            image: 'http://cdn.ebaumsworld.com/mediaFiles/picture/1035099/85708057.jpg',
            info: {
                description:
                    'This campaign highlights which AAA studio will take the lead in launching the first fully on-chain game by Q3 2025.',
                fees: '1',
            },
            contract_address: '0xDEF123456789ABC02',
            start_date: '2024-11-01',
            end_date: '2025-09-30',
        },
        {
            title: 'Which platform will launch the most Web3 games by the end of 2025?',
            options: ['EPIC GAMES', 'STEAM', 'XBOX'],
            image: 'http://cdn.ebaumsworld.com/mediaFiles/picture/1035099/85708057.jpg',
            info: {
                description:
                    'This campaign focuses on identifying which gaming platform will dominate in Web3 game launches by the end of 2025.',
                fees: '1',
            },
            contract_address: '0x123ABC456DEF78903',
            start_date: '2024-11-01',
            end_date: '2025-12-31',
        },
        {
            title: 'Will Web3 Gaming hit $50 billion market cap before Dec 2024?',
            options: ['YES', 'NO'],
            image: 'http://cdn.ebaumsworld.com/mediaFiles/picture/1035099/85708057.jpg',
            info: {
                description:
                    'This campaign forecasts the growth trajectory of Web3 Gaming and its potential to reach a $50 billion market cap by Dec 2024.',
                fees: '1',
            },
            contract_address: '0x789DEF123ABC45604',
            start_date: '2024-11-01',
            end_date: '2024-12-31',
        },
        {
            title: 'What will be the biggest gaming blockchain with highest number of users?',
            options: ['SOLANA', 'SUI', 'POLYGON'],
            image: 'http://cdn.ebaumsworld.com/mediaFiles/picture/1035099/85708057.jpg',
            info: {
                description:
                    'This campaign assesses the competitive landscape of top blockchains aiming for the highest user base in gaming.',
                fees: '1',
            },
            contract_address: '0x456ABC789DEF12305',
            start_date: '2024-11-01',
            end_date: '2025-12-31',
        },
        {
            title: 'Which AAA Web3 game will hit its first one million users?',
            options: ['GUNZILLA', 'STAR ATLAS'],
            image: 'http://cdn.ebaumsworld.com/mediaFiles/picture/1035099/85708057.jpg',
            info: {
                description:
                    'This campaign evaluates the first AAA Web3 game likely to reach one million users.',
                fees: '1',
            },
            contract_address: '0xDEF789123ABC45606',
            start_date: '2024-11-01',
            end_date: '2025-12-31',
        },
        {
            title: 'Will Turf Gaming hit one million IDs before Dec 2024?',
            options: ['YES', 'NO'],
            image: 'turf_gaming_million_ids.png',
            info: {
                description:
                    "This campaign focuses on Turf Gaming's growth potential and the milestone of reaching one million IDs by Dec 2024.",
                fees: '1',
            },
            contract_address: '0xABC789DEF12345607',
            start_date: '2024-11-01',
            end_date: '2024-12-31',
        },
    ],
};
