export const ADMIN_FACTORY_ABI = [
    {
        type: 'constructor',
        inputs: [
            {
                name: 'platformAddress',
                type: 'address',
            },
            {
                name: 'platformFee',
                type: 'uint256',
            },
        ],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'DEFAULT_ADMIN_ROLE',
        inputs: [],
        outputs: [{ name: '', type: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'deployMarket',
        inputs: [
            {
                name: '_tokenAddress',
                type: 'address',
            },
            {
                name: '_description',
                type: 'string',
            },
            {
                name: '_outcomes',
                type: 'string[]',
            },
            {
                name: '_tokenDecimals',
                type: 'uint256',
            },
            {
                name: '_endTimestamp',
                type: 'uint256',
            },
            { name: '_minPrice', type: 'uint256' },
            { name: '_maxPrice', type: 'uint256' },
        ],
        outputs: [{ name: '', type: 'address' }],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'getAllMarkets',
        inputs: [],
        outputs: [{ name: '', type: 'address[]' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getAllUserMarkets',
        inputs: [{ name: '_account', type: 'address' }],
        outputs: [{ name: '', type: 'address[]' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getPlatformDetails',
        inputs: [],
        outputs: [
            { name: '', type: 'address' },
            { name: '', type: 'uint256' },
        ],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'getRoleAdmin',
        inputs: [{ name: 'role', type: 'bytes32' }],
        outputs: [{ name: '', type: 'bytes32' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'grantMarketCreatorRole',
        inputs: [{ name: '_account', type: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'grantRole',
        inputs: [
            { name: 'role', type: 'bytes32' },
            { name: 'account', type: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'hasRole',
        inputs: [
            { name: 'role', type: 'bytes32' },
            { name: 'account', type: 'address' },
        ],
        outputs: [{ name: '', type: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'predictionCount',
        inputs: [],
        outputs: [{ name: '', type: 'uint256' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'renounceRole',
        inputs: [
            { name: 'role', type: 'bytes32' },
            {
                name: 'callerConfirmation',
                type: 'address',
            },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'revokeMarketCreatorRole',
        inputs: [{ name: '_account', type: 'address' }],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'revokeRole',
        inputs: [
            { name: 'role', type: 'bytes32' },
            { name: 'account', type: 'address' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'function',
        name: 'supportsInterface',
        inputs: [{ name: 'interfaceId', type: 'bytes4' }],
        outputs: [{ name: '', type: 'bool' }],
        stateMutability: 'view',
    },
    {
        type: 'function',
        name: 'updatePlatformDetails',
        inputs: [
            { name: '_address', type: 'address' },
            { name: '_fee', type: 'uint256' },
        ],
        outputs: [],
        stateMutability: 'nonpayable',
    },
    {
        type: 'event',
        name: 'MarketDeployed',
        inputs: [
            {
                name: 'tokenAddress',
                type: 'address',
                indexed: false,
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'PlatformDetailsUpdated',
        inputs: [
            {
                name: 'platformAddress',
                type: 'address',
                indexed: false,
            },
            {
                name: 'platformFee',
                type: 'uint256',
                indexed: false,
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'RoleAdminChanged',
        inputs: [
            {
                name: 'role',
                type: 'bytes32',
                indexed: true,
            },
            {
                name: 'previousAdminRole',
                type: 'bytes32',
                indexed: true,
            },
            {
                name: 'newAdminRole',
                type: 'bytes32',
                indexed: true,
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'RoleGranted',
        inputs: [
            {
                name: 'role',
                type: 'bytes32',
                indexed: true,
            },
            {
                name: 'account',
                type: 'address',
                indexed: true,
            },
            {
                name: 'sender',
                type: 'address',
                indexed: true,
            },
        ],
        anonymous: false,
    },
    {
        type: 'event',
        name: 'RoleRevoked',
        inputs: [
            {
                name: 'role',
                type: 'bytes32',
                indexed: true,
            },
            {
                name: 'account',
                type: 'address',
                indexed: true,
            },
            {
                name: 'sender',
                type: 'address',
                indexed: true,
            },
        ],
        anonymous: false,
    },
    { type: 'error', name: 'AccessControlBadConfirmation', inputs: [] },
    {
        type: 'error',
        name: 'AccessControlUnauthorizedAccount',
        inputs: [
            { name: 'account', type: 'address' },
            {
                name: 'neededRole',
                type: 'bytes32',
            },
        ],
    },
    {
        type: 'error',
        name: 'InvalidAmount',
        inputs: [{ name: 'error', type: 'string' }],
    },
    {
        type: 'error',
        name: 'InvalidDescription',
        inputs: [{ name: 'error', type: 'string' }],
    },
    {
        type: 'error',
        name: 'InvalidOutcomes',
        inputs: [{ name: 'error', type: 'string' }],
    },
    {
        type: 'error',
        name: 'InvalidResolveTime',
        inputs: [{ name: 'error', type: 'string' }],
    },
];
