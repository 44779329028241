import React from 'react';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { PREDICTION_MARKET } from '../../../config/config.wods';

const Card = ({ elem }) => (
    <div className="cards min-w-[400px] max-w-xs border border-gray-300 shadow-lg my-4 p-4 flex flex-col justify-between">
        <div className="main-card-content-container">
            <div className="cards-img flex items-center justify-start gap-4 mb-4">
                <img
                    src={elem.image}
                    alt=""
                    className="w-[64px] h-[64px] border border-gray-400 shadow-sm"
                />
                <div className="cards-title text-gray-200">{elem.title}</div>
            </div>
            <small className="text-sm text-gray-400 block mb-4">
                {elem.title}
            </small>
        </div>
        <div className="cards-options flex items-center justify-between gap-2">
            <button className="options flex-1 py-2 text-sm font-medium text-white bg-green-500 hover:bg-green-600 transition">
                Yes
            </button>
            <button className="options flex-1 py-2 text-sm font-medium text-white bg-yellow-500 hover:bg-yellow-600 transition">
                No
            </button>
        </div>
    </div>
);

const ListItem = ({ label, value }) => (
    <div className="flex justify-between border-b border-gray-600 pb-2">
        <span>{label}</span>
        <span className="font-semibold text-green-400">{value}</span>
    </div>
);

const CategoryButton = ({ category }) => (
    <button className="py-2 px-6 bg-yellow-500 hover:bg-yellow-400 text-black font-semibold transition">
        {category}
    </button>
);

const TopDegensList = ({ degenNames }) => (
    <ul className="space-y-2">
        {degenNames.map((name, idx) => (
            <li
                key={idx}
                className="flex justify-between border-b border-gray-600 pb-2"
            >
                <span className="font-medium">{name}</span>
                <span className="text-yellow-400 font-semibold">
                    {idx === 0 ? '200 M' : idx === 1 ? '50 M' : '90 M'}
                </span>
            </li>
        ))}
    </ul>
);

export default function Campaign() {
    const renderMarket = PREDICTION_MARKET.map((elem, index) => (
        <Card key={index} elem={elem} />
    ));

    const topDegens = ['Messi', 'Ronaldo', 'Ronaldo'];
    const wodsTokenData = [
        { label: 'Price', value: '$420' },
        { label: 'Market Cap', value: '$69M' },
    ];
    const categories = ['Politics', 'Crypto', 'AI', 'Sports', 'Business'];

    return (
        <>
            <Header />
            <div className="campaign-creator-dashboard box">
                <div>
                    <div className="glitch-text text-yellow-500 cursor-pointer text-lg">
                        Trending Markets
                    </div>
                    <div className="relative overflow-hidden">
                        <div className="flex space-x-4 animate-slide">
                            {renderMarket}
                        </div>
                    </div>
                </div>

                <div className="flex w-full justify-between space-x-4 pt-6">
                    <div className="p-4 bg-gray-800 text-white w-[50%] border border-gray-700">
                        <div className="glitch-text text-yellow-500 cursor-pointer text-2xl font-bold mb-4">
                            Top Degens
                        </div>
                        <TopDegensList degenNames={topDegens} />
                    </div>

                    <div className="p-6 bg-gray-800 text-white w-[50%] border border-gray-700">
                        <div className="glitch-text text-yellow-500 cursor-pointer text-2xl font-bold mb-6">
                            Wods Token
                        </div>
                        <div className="space-y-4 text-lg">
                            {wodsTokenData.map((item, idx) => (
                                <ListItem key={idx} {...item} />
                            ))}
                        </div>
                        <div className="mt-6 flex gap-4">
                            <button className="w-full py-2 px-4 bg-yellow-500 hover:bg-yellow-400 text-black font-bold transition duration-200">
                                Community
                            </button>
                            <button className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-400 text-white font-bold transition duration-200">
                                Buy now
                            </button>
                        </div>
                    </div>
                </div>

                <div className="main-prediction-market-container min-h-screen py-6 text-white">
                    <div className="header-container text-start mb-6">
                        <h1 className="text-4xl font-bold text-yellow-500">
                            Prediction Market
                        </h1>
                        <p className="text-gray-400 mt-2">
                            Explore trending topics and make your predictions!
                        </p>
                    </div>

                    <div className="filter-buttons-container flex justify-start gap-4 mb-8">
                        {categories.map((category, idx) => (
                            <CategoryButton key={idx} category={category} />
                        ))}
                    </div>

                    <div className="flex flex-wrap justify-start gap-6">
                        {renderMarket}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
